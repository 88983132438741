<template>
  <div>
    <components is="itemsPlataform" ></components>

  </div>
</template>

<script>
import itemsPlataform from './components/itemsPlataform.vue'
export default {

name: 'plataform',
components: {
  itemsPlataform
},
}
</script>

<style lang="css" scoped>
</style>
